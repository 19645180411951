import { call, ForkEffect, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { DataUtility } from "@utils/DataUtility";
import { getErrorCodes } from "@utils/ErrorMessageUtils";

import { ProgramRepository } from "@repositories/ProgramRepository";

import { CourseRepository } from "@common/domain/repositories/CourseRepository";

import { ProgramModel } from "@models/Program";

import { CourseStatus, ProgramStatus } from "@constants/config";

import { enrolledProgramListFail, enrolledProgramListSuccess, enrollProgramFail, enrollProgramSuccess,
     fetchCourseProgramListFail, fetchCourseProgramListSuccess, fetchExploreProgramListFail, 
     fetchExploreProgramListSuccess, fetchProgramDetailFail, fetchProgramDetailSuccess, 
     fetchProgramList, fetchProgramListFail, fetchProgramListSuccess,fetchWadhwaniProgramListFail,
     fetchInstituteProgramListSuccess,fetchInstituteProgramListFail, fetchWadhwaniProgramListSuccess,IFetchInstituteProgramAction, programActions } from "./actions";
import { fetchProgramListSelector } from "./selectors";


export function* fetchProgramCoursesInfo(userProgramDetailResponse, action) {
    const { userIds } = action.payload;
    if (DataUtility.isEmpty(userProgramDetailResponse)) {
        yield call(fetchProgramListSaga, fetchProgramList(action.payload));
    } else {
        yield call(fetchProgramListSaga, fetchProgramList({ collectionRootIds: userProgramDetailResponse?.map(response => response.collectionRootId) }));
    }
    const response = yield (select(fetchProgramListSelector));
    const programDetailResponse: ProgramModel = response[0];
    const actualCourseList = [];

    if (userProgramDetailResponse?.length > 0) {
        const userProgramResponse = userProgramDetailResponse[0];
        const filterCourseRoots = userProgramResponse?.courseRoots?.filter(course => course?.status === ProgramStatus.COMPLETED || course?.status === ProgramStatus.IN_PROGRESS || course?.status === ProgramStatus.COMPLETED_PASS);
        const filteredCourseRootIds = filterCourseRoots?.map(course => course?.courseRootId);
        if (filteredCourseRootIds?.length > 0) {
            yield call(startedCoursesByUser, { userIds, filteredCourseRootIds, programDetailResponse, actualCourseList });
        }
        const filterNotStartedCourseRoots = userProgramResponse?.courseRoots?.filter(course => course?.status === ProgramStatus.NOT_STARTED);
        if (filterNotStartedCourseRoots?.length > 0) {
            yield call(notStartedCoursesByUser, { filterNotStartedCourseRoots, programDetailResponse, actualCourseList });
        }
    } else {
        const courseRootIds = programDetailResponse?.getCourseRoots()?.map(course => course?.courseRootId);
        if (courseRootIds?.length > 0) {
            yield call(notEnrolledProgramCourses, { courseRootIds, programDetailResponse, actualCourseList });
        }
    }
    if (!DataUtility.isEmpty(userProgramDetailResponse)) {
        programDetailResponse.setIsUserEnrolled(true);
        programDetailResponse.setUserProgramResponse(userProgramDetailResponse[0]);
    }
    const sortByOrder = (a, b) => a?.order - b?.order;
    programDetailResponse.setCourseRoots(actualCourseList.sort(sortByOrder));
    return programDetailResponse;
}

export function* startedCoursesByUser(payload) {
    const { userIds, filteredCourseRootIds, programDetailResponse, actualCourseList } = payload;
    const courseUserResponse = yield call(CourseRepository.fetchEnrolledCourses, { userId: userIds, courseRootIds: filteredCourseRootIds, limit: 100 });
    const courseIds = courseUserResponse?.courses.map(course => course?.courseId);
    if (courseIds?.length > 0) {
        const courses = yield call(CourseRepository.fetchCourses, courseIds, [], true);
        const missingCourseIds = filteredCourseRootIds.filter(x => !courseIds.includes(x));
        if (missingCourseIds?.length > 0) {
            yield call(notEnrolledProgramCourses, { missingCourseIds, programDetailResponse, actualCourseList });
            yield call(ProgramRepository.syncProgramData, {programId: programDetailResponse?.getCollectionRootId(), courseRootIds: missingCourseIds?.toString()})
        }
        for (let i = 0; i < courses?.length; i++) {
            const findCourse = programDetailResponse?.getCourseRoots()?.find(course => course?.courseRootId === courses[i]?.courseRootId);
            const findCourseUserResponse = courseUserResponse?.courses?.find(course => course?.courseId === courses[i]?.id)
            actualCourseList.push({
                ...courses[i],
                weightage: findCourse?.weightage,
                competency: findCourse?.competency,
                hasCertificate: findCourse?.hasCertificate,
                order: findCourse?.order,
                score: findCourseUserResponse?.score,
                userCourseStatus: findCourseUserResponse?.status
            })
        }
    }
}

export function* notStartedCoursesByUser(payload) {
    const { filterNotStartedCourseRoots, programDetailResponse, actualCourseList } = payload;
    const filteredNotStartedCourseRootIds = filterNotStartedCourseRoots?.map(course => course?.courseRootId);    
    const notEnrolledCoursesObj = yield call(CourseRepository.fetchLatestCourses, { courseRootIds: filteredNotStartedCourseRootIds, limit: 100 });
    const notEnrolledCourses = notEnrolledCoursesObj?.courses;
    for (let i = 0; i < notEnrolledCourses?.length; i++) {
        const findCourse = programDetailResponse?.getCourseRoots()?.find(course => course?.courseRootId === notEnrolledCourses[i]?.courseRootId);
        actualCourseList.push({
            ...notEnrolledCourses[i],
            weightage: findCourse?.weightage,
            competency: findCourse?.competency,
            hasCertificate: findCourse?.hasCertificate,
            order: findCourse?.order,
            score: null,
            userCourseStatus: null
        });
    }
}

export function* notEnrolledProgramCourses(payload) {
    const { courseRootIds, programDetailResponse, actualCourseList } = payload;
    const coursesObj = yield call(CourseRepository.fetchLatestCourses, { courseRootIds, limit: 100 });
    const courses = coursesObj?.courses;
    for (let k = 0; k < programDetailResponse?.getCourseRoots()?.length; k++) {
        const program = programDetailResponse?.getCourseRoots()[k];
        const findCourse = courses?.find(course => course?.courseRootId === program?.courseRootId);
        actualCourseList.push({
            ...findCourse,
            weightage: program?.weightage,
            competency: program?.competency,
            hasCertificate: program?.hasCertificate,
            order: program?.order,
            score: null,
            userCourseStatus: null
        });
    }
}

export function* fetchProgramDetailSaga(action) {
    try {
        const userProgramDetailResponse = yield call(ProgramRepository.fetchUserProgram, action.payload);
        const programDetailResponse = yield call(fetchProgramCoursesInfo, userProgramDetailResponse, action);
        yield put(fetchProgramDetailSuccess(programDetailResponse));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchProgramDetailFail(error));
    }
}

export function* fetchProgramListSaga(action) {
    try {
        const response = yield call(ProgramRepository.fetchProgramList, action.payload);
        yield put(fetchProgramListSuccess({ programList: response.programList, total: response.total, status: action.payload.status, pageNumber: action.payload.pageNumber }));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchProgramListFail(error));
    }
}

export function* exploreProgramListSaga(action) {
    try {
        const response = yield call(ProgramRepository.fetchProgramList, action.payload);
        yield put(fetchExploreProgramListSuccess({ programList: response.programList, total: response.total, status: action.payload.status, pageNumber: action.payload.pageNumber }));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchExploreProgramListFail(error));
    }
}

export function* enrollProgramSaga(action) {
    try {
        const response = yield call(ProgramRepository.enrollProgram, action.payload);

        const programDetailResponse = yield call(fetchProgramCoursesInfo, [response], action);
        yield put(enrollProgramSuccess(programDetailResponse));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(enrollProgramFail(error));
    }
}

export function* enrolledProgramListSaga(action) {
    try {
        const enrolledProgramList = [];
        const userProgramDetailResponse = yield call(ProgramRepository.fetchUserProgram, action.payload);
        yield call(fetchProgramListSaga, fetchProgramList({ collectionRootIds: userProgramDetailResponse?.map(response => response.collectionRootId) }));
        const programResponse: ProgramModel[] = yield (select(fetchProgramListSelector));
        if (!DataUtility.isEmpty(programResponse) && !DataUtility.isEmpty(userProgramDetailResponse)) {
            for (let program of programResponse) {
                const findUserProgramResponse = userProgramDetailResponse?.find(prog => prog?.collectionId === program?.getCollectionRootId());
                if (findUserProgramResponse) {
                    program.setUserProgramResponse(findUserProgramResponse);
                    enrolledProgramList.push(program);
                }
            }
        }
        yield put(enrolledProgramListSuccess(enrolledProgramList));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(enrolledProgramListFail(error));
    }
}

export function* courseProgramListSaga(action) {
    try {
        const response = yield call(ProgramRepository.fetchProgramList, action.payload);
        yield put(fetchCourseProgramListSuccess({ programList: response.programList, total: response.total, status: action.payload.status, pageNumber: action.payload.pageNumber }));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchCourseProgramListFail(error));
    }
}

export function* instituteProgramListSaga(
    action: IFetchInstituteProgramAction,
  ) {
    try {
      const response = yield call(
        ProgramRepository.fetchInstitutePrograms,
        action.payload,
      );
      yield put(
        fetchInstituteProgramListSuccess({
          programList: response.programList,
          total: response.total,
          pageNumber: action.payload.pageNumber,
        }),
      );
    } catch (e) {
      const error = getErrorCodes(e);
      yield put(fetchInstituteProgramListFail(error));
    }
  }
  
  export function* wadhwaniProgramListSaga(action: IFetchInstituteProgramAction) {
    try {
      const response = yield call(
        ProgramRepository.fetchInstitutePrograms,
        action.payload,
      );
      yield put(
        fetchWadhwaniProgramListSuccess({
          programList: response.programList,
          total: response.total,
          pageNumber: action.payload.pageNumber,
        }),
      );
    } catch (e) {
      const error = getErrorCodes(e);
      yield put(fetchWadhwaniProgramListFail(error));
    }
  }


export function* watchProgram(): Generator<ForkEffect> {
    yield takeLatest(programActions.PROGRAM_DETAIL, fetchProgramDetailSaga);
    yield takeEvery(programActions.PROGRAM_LIST, fetchProgramListSaga);
    yield takeLatest(programActions.ENROLL_PROGRAM, enrollProgramSaga);
    yield takeLatest(programActions.ENROLLED_PROGRAM_LIST, enrolledProgramListSaga);
    yield takeLatest(programActions.EXPLORE_PROGRAM_LIST, exploreProgramListSaga);
    yield takeLatest(programActions.COURSE_PROGRAM_LIST, courseProgramListSaga);
    yield takeLatest(programActions.INSTITUTE_PROGRAM_LIST,instituteProgramListSaga);
    yield takeLatest(programActions.WADHWANI_PROGRAM_LIST, wadhwaniProgramListSaga);
}







