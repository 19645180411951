import { deserializeJsonObj, deserializeJsonObjCollection } from "@utils/ObjectMapperUtil";

import { IEnrollProgram, IFetchProgram, ISyncProgram } from "@redux/program/interface";

import { ProgramModel } from "@models/Program";

import { ApiClient } from "@network/client";
import { APIv1 } from "@network/constants";
import { IApiClient } from "@network/interfaces";


const ENDPOINTS = {
    fetchProgramList: () => `${APIv1}/courseservice/courses/collections`,
    fetchUserProgram: () => `${APIv1}/courseservice/courses/collection/users`,
    enrollProgram: (programId: string, userId: string) => `${APIv1}/courseservice/courses/collection/${programId}/user/${userId}`,
    fetchInstituteProgramList: (userId: string) => `${APIv1}/courseservice/users/${userId}/institute/collections`,
    syncProgramCourses: (programId: string) => `${APIv1}/courseservice/courses/collection/${programId}/syncPrograms`,
}

class ProgramRepository {
    private apiClient: IApiClient;
    constructor(apiClient: IApiClient) {
        this.apiClient = apiClient;
    }

    public fetchProgramList = async (payload) => {
        const { page, limit, status, searchTerm, collectionRootIds, userIds, languageCode, courseRootIds } = payload;
        const response = await this.apiClient.get(ENDPOINTS.fetchProgramList(), { page, limit, status, searchTerm, collectionRootIds, userIds, languageCode, courseRootIds });
        return { programList: deserializeJsonObjCollection(response.courseCollections, ProgramModel, 'fetchProgramList') as ProgramModel[], total: response.totalCount };
    }

    public fetchUserProgram = async (payload) => {
        const response = await this.apiClient.get(ENDPOINTS.fetchUserProgram(), payload);
        return response.userCourseCollection;
    }

    public enrollProgram = async (payload: IEnrollProgram) => {
        const { programId, userIds } = payload;
        const response = await this.apiClient.post(ENDPOINTS.enrollProgram(programId, userIds));
        return response.userCourseCollection;
    }

    public fetchInstitutePrograms = async payload => {
        const { pageNumber, limit, userId, instituteIds, language } = payload;
        const response = await this.apiClient.get(
          ENDPOINTS.fetchInstituteProgramList(userId),
          {
            pageNumber,
            limit,
            instituteIds,
            language,
          },
        );
        return {
          programList: deserializeJsonObjCollection(
            response.collections,
            ProgramModel,
            'fetchInstitutePrograms',
          ) as ProgramModel[],
          total: response.totalCount,
        };
    };

    public syncProgramData = async (payload: ISyncProgram) => {
      const { programId, courseRootIds } = payload;
      const response = await this.apiClient.put(ENDPOINTS.syncProgramCourses(programId), {courseRootIds});
      return response;
  }
}


const programRepository = new ProgramRepository(ApiClient);

export { programRepository as ProgramRepository };




