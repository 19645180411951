import { FEATURE_NAME } from "../../redux/constants";


export interface IProfileWO {
  role: string[];
  deleted: false;
  userId: string;
  source: string;
  candidateId: string;
  firstName: string;
  lastName: string;
  gender: string;
  cookieConsents: string[];
  preferredLanguage: string;
  email: string;
  lmsUserId: number;
  lmsVendor: string;
  createdAt?: string;
  updatedAt?: string;
  id?: string;
  restoreId?: string;
}

import { IDepartmentPayload, IGovernmentPayload } from "@common/redux/auth/interface";
import { IState } from "@store/webInterfaces";
import { JsonProperty } from "json-object-mapper";
import { ILocation } from "./LocationModel";
import { userSource } from "@constants/config";


export interface IExpertDetails {
  expertType: string[];
  title?: string;
  visibility?: string;
}

export interface IProfile {
  role: string[];
  deleted: false;
  userId: string;
  source: string;
  candidateId: string;
  firstName: string;
  lastName: string;
  gender: string;
  title: string;
  cookieConsents: string[];
  preferredLanguage: string;
  email: string;
  lmsUserId: number;
  lmsVendor: string;
  createdAt?: string;
  updatedAt?: string;
  id?: string;
  bio: string;
  linkedInUrl: string;
  twitterUrl: string;
  facebookUrl: string;
  calendlyUrl: string;
  location: IProfileLocation | {};
  profileImageUuid: string;
  educationQualification: string;
  yearOfBirth: number;
  countryCode: string;
  phoneNumber: string;
  restoreId?: string;
  department: IDepartmentPayload;
  government: IGovernmentPayload;
  betaFeatures?: string[];
  productTours: IProductTour[];
  aspiringSectors?: string[];
  placementStatus?: string;
  educationDetails?: IEducationalDetails[];
  employmentDetails?: IEmploymentDetails[];
  languagesKnown: string[];
  workExperience: number;
  expertDetails: IExpertDetails;
  tags: ITags;
  summary: string;
  calcomUrl: string;
  educationorEmployementDataUpdatedOn?: string;
  educationOrEmploymentType: string;
}

export interface IJobRole {
  groupName?: string;
  groupValues?: string[];
}
export interface ITags {
  sector?: string[];
  jobRole?: IJobRole[]
}
export interface IEmploymentDetails {
  employerName: String,
  employmentSector: String,
  startDate: String,
  endDate?: String,
  salary: {
    currency: string,
    amount: number,
    duration: string,
  },
  employmentType: string,
  documents: string,
  description: string,
  selfEmployedDetails: {
    employeesCount: number,
    revenue: {
      currency: string,
      amount: number,
      duration: string,
    }
  }
}

export interface IEducationalDetails {
  instituteName: String,
  degree: String,
  startDate?: String,
  graduationDate: String,
}

export interface IProductTour {
  name: string;
  acknowledgedAt: string
  status: ProductTourStatus;
}

enum ProductTourStatus {
  Completed = 'completed',
  CompletedSkipped = 'completed_skipped'
}

export interface ISetFilterData {
  heading?: string;
  data?: string;
}

export interface IProfileLocation {
  city: {
    id: number;
    name: string;
    displayName: string;
  };
}

export interface IUserEducationQualification {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface IMapUserInvitationPayload {
  userEmail: string,
  userId: string
}

export interface IMapUserInvitationPayloadAction {
  type: string,
  payload: IMapUserInvitationPayload
}

export enum Roles {
  Student = "STUDENT",
  Faculty = "FACULTY",
}

export interface IDepartment {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  ministries?: IDepartment[]
}

export interface IMinistry {
  id: number;
  name: string;
}

export interface IMinistryGroup {
  hasSubMinistry: boolean;
  id: number;
  name: string;
}

export interface IDepartmentModel {
  ministry?: IMinistry;
  ministryGroup?: IMinistryGroup[];
}

export interface IStateModel {
  id?: number;
  name?: string;
}

export interface IGovernment {
  isCentral: boolean;
  state?: IStateModel;
}

export interface IDepartmentResponseModel {
  ministry?: IMinistry;
  ministryGroup?: IMinistryGroup;
}

export class ProfileModel {
  @JsonProperty({ name: "cookieConsents", required: false })
  private cookieConsents: string = "";

  @JsonProperty({ name: "countryCode", required: false })
  private countryCode = "";

  @JsonProperty({ name: "createdAt", required: false })
  private createdAt: string = "";

  @JsonProperty({ name: "deleted", required: false })
  private deleted = false;

  @JsonProperty({ name: "email", required: false })
  private email: string = "";

  @JsonProperty({ name: "firstName", required: true })
  private firstName = "";

  @JsonProperty({ name: "gender", required: false })
  private gender: string = "";

  @JsonProperty({ name: 'languagesKnown', required: false })
  private languagesKnown = [];

  @JsonProperty({ name: 'workExperience', required: false })
  private workExperience = null;

  @JsonProperty({ name: 'summary', required: false })
  private summary = "";

  @JsonProperty({ name: 'expertDetails', required: false })
  private expertDetails = {} as IExpertDetails;

  @JsonProperty({ name: "id", required: true })
  private id = "";

  @JsonProperty({ name: "lastName", required: false })
  private lastName: string = "";

  @JsonProperty({ name: "lmsUserId", required: false })
  private lmsUserId = "";

  @JsonProperty({ name: "lmsVendor", required: false })
  private lmsVendor: string = "";

  @JsonProperty({ name: "middleName", required: false })
  private middleName = "";

  @JsonProperty({ name: "otherStream", required: false })
  private otherStream: string = "";

  @JsonProperty({ name: "phoneNumber", required: false })
  private phoneNumber = "";

  @JsonProperty({ name: "preferredLanguage", required: false })
  private preferredLanguage: string = "";

  @JsonProperty({ name: "profileImageUuid", required: false })
  private profileImageUuid = "";

  @JsonProperty({ name: "role", required: false })
  private role: string[] = [];

  @JsonProperty({ name: "tags", required: false })
  private tags: ITags = {} as ITags;

  @JsonProperty({ name: "streamId", required: false })
  private streamId: string = "";

  @JsonProperty({ name: "updatedAt", required: true })
  private updatedAt = "";

  @JsonProperty({ name: "userId", required: false })
  private userId: string = "";

  @JsonProperty({ name: "userStatus", required: false })
  private userStatus = "";

  @JsonProperty({ name: "bio", required: false })
  private bio: string = "";

  @JsonProperty({ name: "linkedInUrl", required: false })
  private linkedInUrl: string = "";

  @JsonProperty({ name: 'twitterUrl', required: false })
  private twitterUrl: string = '';

  @JsonProperty({ name: 'facebookUrl', required: false })
  private facebookUrl: string = '';

  @JsonProperty({ name: "calendlyUrl", required: false })
  private calendlyUrl: string = "";

  @JsonProperty({ name: "location", required: false })
  private location: ILocation = {} as ILocation;

  @JsonProperty({ name: "profileImageUrl", required: false })
  private profileImageUrl: string = "";

  @JsonProperty({ name: "uuid", required: false })
  private uuid: string = "";

  @JsonProperty({ name: "educationQualification", required: false })
  private educationQualification: string = "";

  @JsonProperty({ name: "yearOfBirth", required: false })
  private yearOfBirth: number = undefined;

  @JsonProperty({ name: "restoreId", required: false })
  private restoreId = "";

  @JsonProperty({ name: "betaFeatures", required: false })
  private betaFeatures: string[] = [];

  @JsonProperty({ name: "productTours", required: false })
  private productTours: IProductTour[] = [];

  @JsonProperty({ name: "aspiringSectors", required: false })
  private aspiringSectors: string[] = [];

  @JsonProperty({ name: "placementStatus", required: false })
  private placementStatus: string = "";

  @JsonProperty({ name: "educationDetails", required: false })
  private educationDetails: IEducationalDetails[] = [];

  @JsonProperty({ name: 'title', required: false })
  private title: string = '';

  @JsonProperty({ name: "employmentDetails", required: false })
  private employmentDetails: IEmploymentDetails[] = [];

  @JsonProperty({ name: "calcomUrl", required: false })
  private calcomUrl: string = "";

  @JsonProperty({ name: "source", required: false })
  private source: string = "";

  @JsonProperty({ name: "educationOrEmploymentType", required: false })
  private educationOrEmploymentType: string = "";

  @JsonProperty({ name: "educationorEmployementDataUpdatedOn", required: false })
  private educationorEmployementDataUpdatedOn: string = "";

  public getYearOfBirth(): number {
    return this.yearOfBirth;
  }

  public getEducationQualification(): string {
    return this.educationQualification;
  }

  public getEmail(): string {
    return this.email;
  }

  public getFirstName(): string {
    return this.firstName;
  }

  public getGender(): string {
    return this.gender;
  }

  public getId(): string {
    return this.id;
  }

  public getCookieConsents(): string {
    return this.cookieConsents;
  }

  public getCountryCode(): string {
    return this.countryCode;
  }

  public getCreatedAt(): string {
    return this.createdAt;
  }

  public getDeleted(): boolean {
    return this.deleted;
  }

  public getLastname(): string {
    return this.lastName;
  }

  public getTitle(): string {
    return this.title;
  }
  public getLmsUserId(): string {
    return this.lmsUserId;
  }

  public getLmsVendor(): string {
    return this.lmsVendor;
  }

  public getMiddleName(): string {
    return this.middleName;
  }

  public getLanguagesKnown(): string[] {
    return this.languagesKnown;
  }

  public getWorkExperience(): number {
    return this.workExperience;
  }

  public getSummary(): string {
    return this.summary;
  }

  public getExpertDetails(): IExpertDetails {
    return this.expertDetails;
  }
  public getOtherStream(): string {
    return this.otherStream;
  }

  public getPhoneNumber(): string {
    return this.phoneNumber;
  }

  public getPreferredLanguage(): string {
    return this.preferredLanguage;
  }

  public getProfileImageUuid(): string {
    return this.profileImageUuid;
  }

  public getCalcomUrl(): string {
    return this.calcomUrl;
  }

  public getRole(): string {
    const roles = this.role;
    return roles && roles.length > 0 ? roles[0] : "";
  }

  public getSector(): string[] {
    return this.tags.sector || [];
  }

  public getJobRole(): IJobRole[] {
    return this.tags.jobRole || [] as IJobRole[];
  }

  public isStudent(): boolean {
    const role = this.getRole();
    return role === Roles.Student;
  }

  public isFaculty(): boolean {
    const role = this.getRole();
    return role === Roles.Faculty;
  }

  public getStreamId(): string {
    return this.streamId;
  }

  public getUpdatedAt(): string {
    return this.updatedAt;
  }

  public getUserId(): string {
    return this.userId;
  }

  public getUserStatus(): string {
    return this.userStatus;
  }

  public getName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public getBio(): string {
    return this.bio;
  }
  public getTwitterUrl(): string {
    return this.twitterUrl;
  }

  public getFacebookUrl(): string {
    return this.facebookUrl;
  }

  public getLinkedInUrl(): string {
    return this.linkedInUrl;
  }

  public getCalendlyUrl(): string {
    return this.calendlyUrl;
  }

  public getLocation(): ILocation {
    return this.location;
  }

  public getProfilePicUrl(): string {
    return this.profileImageUrl;
  }

  public getProfileUuid(): string {
    return this.uuid;
  }

  public getProfileImageUrl(): string {
    return this.profileImageUrl;
  }

  public getRestoreId(): string {
    return this.restoreId;
  }

  public setRestoreId(resId: string): void {
    if (resId) {
      this.restoreId = resId;
    }
  }
  public getBetaFeatures(): string[] {
    return this.betaFeatures || [];
  }

  public isBetaUser(): boolean {
    return (this.betaFeatures && this.betaFeatures.length > 0);
  }

  public isBetaFeatureEnabled(feature: string): boolean {
    return this.betaFeatures && this.betaFeatures.indexOf(feature) !== -1;
  }

  public getProductTours(): IProductTour[] {
    return this.productTours;
  }

  public getAspiringSectors(): string[] {
    return this.aspiringSectors;
  }

  public getPlacementStatus(): string {
    return this.placementStatus;
  }

  public getEducationalDetails(): IEducationalDetails[] {
    return this.educationDetails;
  }

  public getEmploymentDetails(): IEmploymentDetails[] {
    return this.employmentDetails;
  }

  public getUserSource(): string {
    return this.source;
  }

  public isFPOSSOUser(): boolean {
    return this.source === userSource.FPO
  }

  public isTourCompleted(tourName: string): boolean {

    const tour = this.productTours.find(tour => tour.name === tourName);
    if (tour) {
      return tour.status === ProductTourStatus.Completed || tour.status === ProductTourStatus.CompletedSkipped;
    }
    return false;
  }

  public getEducationOrEmploymentType(): string {
    return this.educationOrEmploymentType
  }

  public getEducationorEmployementDataUpdatedOn(): string {
    return this.educationorEmployementDataUpdatedOn
  }

}
