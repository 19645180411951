import { getLanguage } from "@utils/LanguageUtils";
import { messages } from "@utils/Messages";

import { AppLanguage } from "@translations/AppLanguage";
import { i18n } from "@translations/i18n";

import dayjs from "dayjs";
import en from 'dayjs/locale/en';
import es from 'dayjs/locale/es';
import pt from 'dayjs/locale/pt';

export const DATE_FORMAT_3 = "DD.MM.YYYY";
export const DateTimeFormat = { D_M_YYYY: "D/M/YYYY" };
export const DATE_FORMAT_4 = "YYYY-MM-DD HH:mm:00";
export const DATE_FORMAT_5 = "MMMM Do YYYY";
export const DATE_FORMAT_6 = "YYYY-MM-DD";
export const DEFAULT_DATE_FORMAT =  "DD MMM, 'YY";
export const DEFAULT_DATE_TIME_FORMAT = "DD MMM, 'YY | hh:mm A";
export const DATE_TIME_FORMAT_8 = "DD MMM YY, hh:mm A";
export const TIME_FORMAT = "h:mm a";
export const TIME_FORMAT_CAPS = "hh:mm A";
export const DATE_FORMAT_7 = "DD-MM-YYYY, hh:mm A";
export const DATE_FORMAT_8 = "DD/MM/YYYY";
export const DATE_FORMAT_9 = "MMM YYYY";

export const getCalendarLanguage = () => {
  switch(getLanguage()) {
    case AppLanguage.Spanish:
      return es;
    case AppLanguage.Portuguese:
      return pt;
    default:
      return en;
  }
}

const getDateFromTimeStamp = (timeStamp: Date): string => {
  return dayjs(timeStamp)
    .locale(getCalendarLanguage())
    .format("DD MMM, YY");
};

const getHourFromTimeStamp = (timeStamp: Date, meridiem: boolean): string => {
  const am = meridiem ? "a" : "";
  return dayjs(timeStamp)
    .locale(getCalendarLanguage())
    .format("h:mm" + am);
};

const getCurrentDateTime = (): string => {
  return dayjs()
    .locale(getCalendarLanguage())
    ?.toISOString();
};

const getModifiedDateFromTimeStamp = (timeStamp: Date) => {
  return dayjs(timeStamp)
    .locale(getCalendarLanguage())
    .format("DD MMM");
};

const getModifiedDateTimeStamp = (timeStamp: Date): string => {
  return dayjs(timeStamp)
    .locale(getCalendarLanguage())
    .format("DD MMM, YYYY");
};

const getModifiedDay = (timeStamp: string): string => {
  return dayjs(timeStamp, "DD MMM, YYYY")
    .locale(getCalendarLanguage())
    .format("dddd");
};

const getDateTime = (timeStamp: Date, format: string) => {
  dayjs.locale(getCalendarLanguage());
  return dayjs(timeStamp)
    .format(format);
};

export const isBeforeDate = (date1: string, date2: string) => {
  return dayjs(date1).isBefore(date2);
};

export const isSameOrBeforeDay = (date1, date2 = "") => {
  return date1.isSame(date2, 'millisecond') || date1.isBefore(date2, 'millisecond');
};

const convertDateTimeToString = (date: Date): string => {
  return dayjs(date)?.toISOString();
};

const isToday = (timeStamp: Date): string => {
  const convertedTimeStamp = dayjs(timeStamp, "DD/MM/YYYY");
  if (convertedTimeStamp.isSame(new Date(), "day")) {
    return i18n.t(messages.today.key);
  }
  return getDateFromTimeStamp(timeStamp);
};

export const getMomentFormat = (date: string, format: string) => {
  return dayjs(date, format).locale(getCalendarLanguage());
};

export const getDateDifferenceInDays = (
  currentDate: any,
  nextDate: any
): number => {
  return dayjs(nextDate).diff(dayjs(currentDate), "days");
};

export const getCommentHourFromTimeStamp = (
  timeStamp: Date | string,
  meridien: boolean
): string => {
  const am = meridien ? "A" : "";
  return dayjs(timeStamp).format("h:mm " + am);
};

export const disablePastDate = (current) => {
  return current && current.valueOf() < Date.now();
};

// get the current date time in ISO format.
export const getCurrentDateInISO = () => {
  return new Date()?.toISOString();
};

// Returns formated current and next date along with their difference in days
export const getDateFormatedData = (currentDate: any, nextDate: any) => {
  const d1 = DateHelper.getDateTime(currentDate, DATE_FORMAT_3);
  const d2 = DateHelper.getDateTime(nextDate, DATE_FORMAT_3);
  const formatedCurrentDate: any = DateHelper.getMomentFormat(
    d1,
    DATE_FORMAT_3
  );
  const formatedNextDate: any = DateHelper.getMomentFormat(d2, DATE_FORMAT_3);
  const diff = DateHelper.getDateDifferenceInDays(
    formatedCurrentDate,
    formatedNextDate
  );
  return { formatedCurrentDate, formatedNextDate, diff };
};

const isDateBetween = (date: Date, min: string, max: string): boolean =>
  date.getTime() >= new Date(min).getTime() &&
  date.getTime() <= new Date(max).getTime();

// Util function to get the future month from the current month date.
const getMonthDatefromCurrentDate = (noOfMonth: number): Date => {
  const currentDate = new Date();
  return new Date(currentDate.setMonth(currentDate.getMonth() + noOfMonth));
};

const isCurrentDate = (current: Date): boolean => {
  return dayjs(new Date()).isSame(current, "day");
};

const getYear = (date?: Date) => {
  return dayjs(date || undefined).get('year')
}

export const getFormattedDate = (date: string, dateFormat: string) => {
  dayjs.locale(getCalendarLanguage());
  return `${dayjs(date).format(dateFormat)}`;
}

const endAfterStart = (startDate: string, endDate: string) => {
  return new Date(endDate).getTime() > new Date(startDate).getTime();
}

const startDateCheck = (startDate: string, endDate: string) => {
  return new Date(startDate).getTime() > new Date(endDate).getTime();
}

export const disableDaysTillYesterday = (current) => {
  return current > dayjs().startOf('day');
}

const getNearestHour = () => {
  const date = new Date();
  const minutes = date.getMinutes();
  if (minutes !== 0) {
    date.setHours(date.getHours() + 1);
    date.setMinutes(0);
    date.setSeconds(0);
  }
  return dayjs(date);
}

const getDifferenceInMinutes = (startDateTime, endDateTime) => {
  return endDateTime.diff(startDateTime, 'minutes');
}

const getDateAndFormattedTime = (startDateTime: string, endDateTime: string) => {
  return `${dayjs(startDateTime).format(TIME_FORMAT_CAPS)} - ${dayjs(endDateTime).format(TIME_FORMAT_CAPS)}`;
}

const getDateFromUnixEpochTime = (timeStamp: number): string => {
  dayjs.locale(getCalendarLanguage());
  return dayjs.unix(timeStamp).format(DEFAULT_DATE_FORMAT);
};

const getDateTimeFromUnixEpochTime = (timeStamp: number) : any=> {
  dayjs.locale(getCalendarLanguage());
  return dayjs.unix(timeStamp).format(DATE_FORMAT_4);
};

// will give the deffarance in hourse between current and given date
const getDifferenceDateFromUnixEpoch = (timeStamp: number) => {
  dayjs.locale(getCalendarLanguage());
  const now = dayjs().unix();
  const time = dayjs.unix(timeStamp);
  const currentTime =  dayjs.unix(now);
  return time.diff(currentTime, "hours");
};


const getDateWithTime = (startDateTime: string, endDateTime: string) => {
  return `${DateHelper.getFormattedDate(startDateTime, DEFAULT_DATE_FORMAT)} | ${dayjs(startDateTime).format(TIME_FORMAT_CAPS)} - ${dayjs(endDateTime).format(TIME_FORMAT_CAPS)}`;
}

const addDaysInCurrentDateInIsoFormat = (noOfDays: number) => {
  const currentDate = new Date();
  const newDate = new Date(currentDate);
  newDate.setDate(currentDate.getDate() + noOfDays);
  const isoDate = newDate.toISOString();
  return isoDate;
}

const convertDate = (dateStr:string) => {
  const date = new Date(dateStr); 
  date.setDate(1)
  return date.toISOString();
};

export const getDateForGoogleAnalyticsEvents = () =>{
  const currentDate = new Date();
  // Get individual parts
  const day = currentDate.toLocaleDateString('en-US', { weekday: 'short' });
  const month = currentDate.toLocaleDateString('en-US', { month: 'short' });
  const date = currentDate.getDate();
  const year = currentDate.getFullYear();
  const time = currentDate.toLocaleTimeString('en-US', { hour12: false });
  const timeZone = currentDate.toTimeString().match(/\(.*\)$/)[0]; // Extract timezone info
  // Combine them to match your format
  const formattedDate = `${day} ${month} ${date} ${year} ${time} GMT+0530 ${timeZone}`;
  return formattedDate;
}

export const DateHelper = {
  getDateFromTimeStamp,
  getHourFromTimeStamp,
  getCurrentDateTime,
  getModifiedDateFromTimeStamp,
  getModifiedDateTimeStamp,
  getModifiedDay,
  getDateTime,
  isBeforeDate,
  convertDateTimeToString,
  isToday,
  getMomentFormat,
  getDateDifferenceInDays,
  getCommentHourFromTimeStamp,
  getDateFormatedData,
  disablePastDate,
  getCurrentDateInISO,
  isDateBetween,
  getMonthDatefromCurrentDate,
  isCurrentDate,
  isSameOrBeforeDay,
  getYear,
  getFormattedDate,
  endAfterStart,
  disableDaysTillYesterday,
  getNearestHour,
  getDifferenceInMinutes,
  getDateAndFormattedTime,
  getDateFromUnixEpochTime,
  getDateWithTime,
  getDifferenceDateFromUnixEpoch,
  getDateTimeFromUnixEpochTime,
  startDateCheck,
  addDaysInCurrentDateInIsoFormat,
  convertDate
};
